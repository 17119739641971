import { runWithAdal } from 'react-adal';
import { authContext } from './adal';
import {getRpmToken} from './app/service/idp'

const DO_NOT_LOGIN = false;

runWithAdal(
    authContext,
    () => { 
        getRpmToken().then(function(){
            require('./index2');
        })
        
    },
    DO_NOT_LOGIN
);