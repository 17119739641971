import { AuthenticationContext } from 'react-adal';

const adalConfig = {
  tenant: 'BEFCF223-5020-41CC-AFC1-AA4B72D5A550',
  clientId: '54656803-9C87-44AA-BE09-FE56E117734E',
  endpoints: {
    api: 'BEFCF223-5020-41CC-AFC1-AA4B72D5A550'
  },
  //navigateToLoginRequestUrl: false,
  cacheLocation: 'localStorage',
};


export const authContext = new AuthenticationContext(adalConfig);

export const getToken = (): string => authContext.getCachedToken(adalConfig.clientId);
