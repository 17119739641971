import React, { Component } from 'react';
import { HashRouter, Switch, Route } from "react-router-dom";
import './index.scss';

//
import DefaultLayout from './containers/default-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faComment, faQuestionCircle, faShieldAlt } from '@fortawesome/free-solid-svg-icons';

//
import TrackPageViews from './google';

// Pages
const Home = React.lazy(() => import('./views/home'));
const Timeline = React.lazy(() => import('./views/timeline'));
const Profile = React.lazy(() => import('./views/profile'));
const Help = React.lazy(() => import('./views/help'));

//
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends Component {

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render() {

    return (
      <div>
        <DefaultLayout />
        <div id="app-body">
          <div className="side-bar">
            <ul className="left-nav list-unstyled">
              <li className="mt-4">
                <a href="#/">
                  <FontAwesomeIcon icon={faHome} size="lg" color="white" className="ml-3 mr-3" />
                  Home
                </a>
              </li>
              <li className="mt-4">
                <a href="/#profile">
                  <FontAwesomeIcon icon={faShieldAlt} size="lg" color="white" className="ml-3 mr-3" />
                  My Profile
                </a>
              </li>
              <li className="mt-4">
                <a href="/#timeline">
                  <FontAwesomeIcon icon={faComment} size="lg" color="white" className="ml-3 mr-3" />
                  Timeline
                </a>
              </li>
              <li className="mt-4">
                <a href="/#help">
                  <FontAwesomeIcon icon={faQuestionCircle} size="lg" color="white" className="ml-3 mr-3" />
                  Help
                </a>
              </li>
            </ul>
          </div>

          <div className="main">
            <div className="container-xl ml-1">
              <div className="pt-3">
                <HashRouter>
                  <React.Suspense fallback={loading()}>
                    <TrackPageViews/>
                    <Switch>
                      <Route exact path="/profile" render={props => <Profile/>} />
                      <Route exact path="/timeline" render={props => <Timeline/>} />
                      <Route exact path="/help" render={props => <Help/>} />
                      <Route path="/" render={props => <Home/>} />
                    </Switch>
                  </React.Suspense>
                </HashRouter>
              </div>
            </div>
          </div>
        </div>
      </div>
     );
    }
  }
  
  export default App;
