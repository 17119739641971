import React, { Component } from 'react';
import './default-layout.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import { authContext, getToken } from '../../adal';
import * as JWT from 'jwt-decode';
import { AzureToken } from '../models/azure-token';


export default class DefaultLayout extends Component {
  render() {

    // Fetch Azure token
    const adalToken = getToken();

    let emailAddress;

    if (adalToken) {
      
      // Parse the Azure token
      const tokenData = JWT.default<AzureToken>(adalToken);

      // Fetch the email address from the token
      if (tokenData) {
        emailAddress = tokenData.upn.toLowerCase();
      }

    }

    return (
      <div>
        <div className="global-nav">
          <div className="global-nav-wrapper">
            <div className="global-nav-left">
              <ul className="nav">
                <li className="global-nav-logo-item">
                  <a href="/">
                    <img alt="Logo" className="global-nav-logo" height="40" src="img/logo.png" width="40" />
                  </a>
                </li>
                <li className="nav-item">
                  <div className="row">
                    <div className="col global-nav-title">My Applications</div>
                    <div className="col-2"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="global-nav-right">
              <ul className="nav">
                <li className="global-nav-item nav-item pr-3">{emailAddress}</li>
                <li className="global-nav-item nav-item pr-3">
                  <button className="btn btn-sm" onClick={() => authContext.logOut()}>
                    <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="global-nav-icon" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}